import React, { useCallback, useEffect, useRef, useState } from 'react';

import { GridApi, ServerSideStoreType, ColDef } from '@ag-grid-community/core';

import Dialog from 'components/Dialog/Dialog';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import QuotaAdjustmentCellRenderer from 'app/components/AdvancedGrid/CellRenderers/QuotaAdjustmentCellRenderer/QuotaAdjustmentCellRenderer';
import buildMeasuresColumnDef from 'app/components/AdvancedGrid/GridHelpers/QuotaGrid/QuotaGridColumnDef';
import { buildMeasuresGridData } from 'app/components/AdvancedGrid/GridHelpers/QuotaGrid/QuotaGridHelper';
import { formatTotalsFooter } from 'app/components/AdvancedGrid/GridHelpers/QuotaGrid/TotalsFooter/TotalsFooterHelper';
import { refetchRowData } from 'app/components/AdvancedGrid/GridHelpers/QuotaGrid/utils/editMeasuresGridData';
import LookupTableDetail from 'app/components/DataPanel/TablesPanel/LookupTableDetail/LookupTableDetail';

import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCoinsort } from 'app/contexts/coinsortProvider';
import { useData } from 'app/contexts/dataProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';
import { SHOW_LOADING_AFTER } from 'app/global/variables';

import {
  LookupTypeEnum,
  GetTerritoryQuotaVariables,
  GetTerritoryQuotaTotalsVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { useGetLookupsForPlanningCycle } from 'app/graphql/hooks/useGetLookupsForPlanningCycle';
import { useUpsertFieldValues } from 'app/graphql/mutations/upsertFieldValues';
import { useGetTerritoryQuotaLazy } from 'app/graphql/queries/getMeasures';
import { useGetTerritoryQuotaTotalsLazy } from 'app/graphql/queries/getTerritoryMeasuresTotals';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import {
  CategoryName,
  GridFields,
  GridHeaders,
  QuotaGridColumnName,
  SelectedQuotaDrillInTerritory,
  UserRoleType
} from 'app/models';

import block from 'utils/bem-css-modules';
import { openPreviewDialogHelper } from 'utils/helpers/sheetsPreviewUtils';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import style from './QuotaGrid.module.pcss';

const b = block(style);

interface QuotaGridProps {
  sheetId: number;
}

const QuotaGrid: React.FC<QuotaGridProps> = ({ sheetId }) => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>(null);
  const [gridData, setGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const [isLookupPreviewOpen, setIsLookupPreviewOpen] = useState<boolean>(false);
  const {
    setShowAccountQuotaDrillIn,
    setSelectedQuotaDrillInTerritory,
    isQuotaAdjustmentEditable,
    setHasGridThresholdBeenExceeded
  } = useGrid();

  const { userRole } = useUser();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const {
    battleCardLookupMap,
    setShouldRefetchBattleCardDataOnNextRender,
    selectedQuotaComponentId,
    selectedBattleCardId,
    quotaBreakdownHierarchies
  } = useBattleCard();
  const { selectedPillIdPlanTargets, getPlanTargets, setOnUpdatePlanTargets } = usePlanTargets();
  const { selectedPlanningCycle, selectedTenant, selectedDeploymentModelId } = useScope();
  const { defaultReportingCurrency } = useLocalization();
  const {
    blockSize,
    refreshGrid,
    setRefreshGrid,
    errorItems,
    setErrorItems,
    setSelectedCell,
    setBulkUpsertQuotaGridFields,
    hasGridThresholdBeenExceeded,
    bulkUpsertQuotaGridFields
  } = useGrid();
  const { isCoinsortRunCompleted } = useCoinsort();
  const { fileUploadInProgress, selectedTable, setSelectedTable, sheetDefinitions } = useData();
  const [isQuotaGridTotalsSlownessEnabled] = useTreatment(SplitFeatures.QUOTA_GRID_TOTALS_SLOWNESS);
  const isQuotaGridBulkUpsertEnabled = isQuotaGridTotalsSlownessEnabled && hasGridThresholdBeenExceeded;
  const showToast = useShowToast();

  const containerRef = useRef(null);

  const [isColumnHighlightingEnabled] = useTreatment(SplitFeatures.COLUMN_HIGHLIGHTING);

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;

  const isUnassignedSelected = selectedPillId === CategoryName.UNASSIGNED;
  const isBattleCardSelected = !selectedPillId || selectedPillIdPlanTargets === 'battlecard';
  const isRootBattleCard = !battleCardLookupMap?.[selectedBattleCardId]?.battlecardParentId;
  const battleCardLocalCurrencyCode = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;
  const battleCardCurrencyConversionRate = battleCardLookupMap?.[selectedBattleCardId]?.conversionRate;
  const invalidConversionReason = battleCardLookupMap?.[selectedBattleCardId]?.invalidConversionReason;

  const [
    getTerritoryGroupMeasures,
    {
      data: territoryGroupMeasures,
      loading: territoryGroupMeasuresLoading,
      fetchMore: fetchMoreGroupMeasures,
      refetch: refetchTerritoryGroupMeasures
    }
  ] = useGetTerritoryQuotaLazy({
    fetchPolicy: 'network-only',
    onError() {
      showToast(formatMessage('QUOTA_GRID_ERROR'), 'danger');
      setIsLoading(false);
    }
  });

  // TODO: quota grid loading state for pinned bottom row TQP-10450 https://varicent.atlassian.net/browse/TQP-10450
  const [
    getTerritoryGroupMeasuresTotals,
    {
      data: territoryGroupMeasuresTotals,
      loading: territoryGroupMeasuresTotalsLoading,
      refetch: refetchTerritoryGroupMeasuresTotals
    }
  ] = useGetTerritoryQuotaTotalsLazy({
    fetchPolicy: 'network-only',
    onError() {
      showToast(formatMessage('QUOTA_GRID_ERROR'), 'danger');
    }
  });

  useEffect(() => {
    if (territoryGroupMeasuresTotals && !territoryGroupMeasuresTotalsLoading && gridApi) {
      gridApi.setPinnedBottomRowData(formatTotalsFooter(territoryGroupMeasuresTotals.getTerritoryQuota.periodicTotals));
    }
  }, [territoryGroupMeasuresTotals, territoryGroupMeasuresTotalsLoading, gridApi]);

  useEffect(() => {
    if (!selectedBattleCardId) {
      setGridData([]);
      setIsLoading(false);
    }
  }, [selectedBattleCardId]);

  useEffect(() => {
    if (refreshGrid) {
      refetchTerritoryGroupMeasures();
      refetchTerritoryGroupMeasuresTotals();
      if (isQuotaGridBulkUpsertEnabled && Object.keys(bulkUpsertQuotaGridFields).length > 0) {
        gridApi?.refreshServerSideStore({ purge: false });
        setBulkUpsertQuotaGridFields({});
      }
      setRefreshGrid(false);
    }
  }, [refreshGrid]);

  useEffect(() => {
    if (fileUploadInProgress.length === 0 && territoryGroupMeasures) {
      getTerritoryGroupMeasures({ variables: getSelectedBattleCardVariables(1, blockSize) });
      getTerritoryGroupMeasuresTotals({
        variables: getSelectedBattleCardVariables(1, blockSize, true)
      });
    }
  }, [fileUploadInProgress]);

  useEffect(() => {
    setErrorItems(errorItems);
  }, [errorItems]);

  useEffect(() => {
    if (!columnDefs) {
      return getQuotaGridData();
    }
    gridApi?.setColumnDefs(columnDefs);
  }, [columnDefs]);

  useEffect(() => {
    setColumnDefs(null);
  }, [sheetId]);

  const hasGridThresholdBeenExceededResponse =
    territoryGroupMeasures?.getTerritoryQuota?.hasGridThresholdBeenExceeded || false;
  useEffect(() => {
    // when territory group is not selected and battle card measures have loaded, show in grid
    if (!territoryGroupMeasures?.getTerritoryQuota || territoryGroupMeasuresLoading) {
      return;
    }
    if ((isBattleCardSelected && selectedBattleCardId) || selectedPillId) {
      setQuotaGridData();
    }

    setHasGridThresholdBeenExceeded(hasGridThresholdBeenExceededResponse);
  }, [territoryGroupMeasures, territoryGroupMeasuresLoading, quotaBreakdownHierarchies, selectedBattleCardId]);

  useEffect(() => {
    const timeoutTerritory = setTimeout(() => {
      if (territoryGroupMeasuresLoading) {
        setIsLoading(true);
      }
    }, SHOW_LOADING_AFTER);

    if (!territoryGroupMeasuresLoading) {
      clearTimeout(timeoutTerritory);
      setIsLoading(false);
    }
    return () => {
      clearTimeout(timeoutTerritory);
    };
  }, [territoryGroupMeasuresLoading]);

  // when pill selection is changed, get data
  useEffect(() => {
    if (!isContributor && (isUnassignedSelected || isRootBattleCard)) {
      return;
    } else if (isContributor && !selectedQuotaComponentId) {
      // when user is Contributor, need to wait until selectedQuotaComponentId to have a value before getQuotaGridData
      // since contributor set this value when the response of getSheetDefinitions for the selected sheet is returned
      return;
    }
    getQuotaGridData();
    setOnUpdatePlanTargets(() => {
      getQuotaGridData();
      setShouldRefetchBattleCardDataOnNextRender(true);
    });
  }, [
    selectedPillIdTDR,
    selectedPillIdPlanTargets,
    selectedQuotaComponentId,
    selectedBattleCardId,
    userRole,
    battleCardLocalCurrencyCode,
    battleCardCurrencyConversionRate,
    invalidConversionReason,
    sheetId
  ]);

  useEffect(() => {
    if (isCoinsortRunCompleted) {
      getQuotaGridData();
    }
  }, [isCoinsortRunCompleted]);

  // eslint-disable-next-line no-restricted-syntax
  const isContributor = userRole === UserRoleType.CONTRIBUTOR;

  const seasonalityFieldId = sheetDefinitions?.find((definition) => {
    return definition.measureName === 'Seasonality';
  })?.measureId;

  const territoryMeasures = territoryGroupMeasures?.getTerritoryQuota;

  const territoryVariables = {
    battlecardId: +selectedBattleCardId,
    quotaComponentId: +selectedQuotaComponentId,
    territoryGroupId: +selectedPillId || null,
    isTQM: false
  };

  const handleOnCellClicked = (event) => {
    if (
      event.colDef.headerName.includes(`${QuotaGridColumnName.QUOTA_ADJUSTMENT}_`) &&
      event.colDef.cellRendererFramework === QuotaAdjustmentCellRenderer
    ) {
      setSelectedCell({
        rowIndex: event.rowIndex,
        value: event.value,
        column: event.colDef.headerName,
        date: event.node?.data[event.colDef.headerName].date,
        measureId: event.node?.data[event.colDef.headerName].id,
        territoryGroupId: event.node?.data?.territoryGroupId,
        ruleId: event.node?.data?.ruleId
      });
    } else {
      setSelectedCell(null);
    }
  };

  const onAccountQuotaClicked = (selectedQuotaTerritory: SelectedQuotaDrillInTerritory): void => {
    setShowAccountQuotaDrillIn(true);
    setSelectedQuotaDrillInTerritory(selectedQuotaTerritory);
  };

  const getGridOptions = () => {
    if (isLoading) {
      return {};
    }

    return {
      headerHeight: CELL_HEIGHT,
      rowHeight: CELL_HEIGHT,
      rowClass: b('gridRow'),
      enableCellChangeFlash: true,
      rowModelType: 'serverSide',
      serverSideStoreType: 'partial' as ServerSideStoreType,
      tooltipShowDelay: 0,
      cacheBlockSize: blockSize,
      suppressCellSelection: true,
      onGridReady: handleOnGridReady,
      stopEditingWhenCellsLoseFocus: true,
      onCellClicked: handleOnCellClicked,
      onCellValueChanged
    };
  };

  const getSeasonalityVariables = (params) => {
    return [
      {
        ...territoryVariables,
        territoryId: params.data.territoryId,
        ruleId: +params.node.data.ruleId,
        territoryGroupId: +params.data?.territoryGroupId,
        fieldId: seasonalityFieldId,
        fieldValue: params?.newValue
      }
    ];
  };

  const [upsertFieldValues] = useUpsertFieldValues();

  const getQuotaGridData = () => {
    const startRow = 1;
    const endRow = blockSize;

    const queryRowsVariables = getQueryVariables(startRow, endRow);
    const queryTotalVariables = getQueryVariables(startRow, endRow, true);

    getTerritoryGroupMeasures({ variables: queryRowsVariables });
    getTerritoryGroupMeasuresTotals({ variables: queryTotalVariables as GetTerritoryQuotaTotalsVariables });
  };

  const {
    data: lookups,
    loading: lookupsLoading,
    refetch: refetchLookups
  } = useGetLookupsForPlanningCycle({
    planningCycleId: selectedPlanningCycle?.id
  });

  const handleOpenPreviewDialog = (): void => {
    openPreviewDialogHelper(
      lookups,
      selectedTable,
      setSelectedTable,
      setIsLookupPreviewOpen,
      selectedTenant.id,
      selectedPlanningCycle.id,
      LookupTypeEnum.Seasonality
    );
  };

  const setQuotaGridData = () => {
    const measuresGridData = buildMeasuresGridData(
      territoryMeasures,
      selectedBattleCardId,
      selectedQuotaComponentId,
      true
    );

    // columnDefs only need to be set once
    if (!columnDefs) {
      setColumnDefs(
        buildMeasuresColumnDef(
          territoryMeasures,
          battleCardLocalCurrencyCode || defaultReportingCurrency, // use the defaultReportingCurrency for formatting if the localCurrencyCode is not provided (needed for contributor view)
          !invalidConversionReason,
          () => {
            if (!isContributor) {
              getPlanTargets(
                selectedBattleCardId,
                selectedQuotaComponentId,
                selectedPlanningCycle?.id,
                selectedDeploymentModelId
              );
            }
          },
          userRole,
          b('totalsHeader'),
          errorItems,
          handleOpenPreviewDialog,
          lookups,
          true,
          quotaBreakdownHierarchies,
          isQuotaAdjustmentEditable,
          +selectedBattleCardId,
          selectedQuotaComponentId,
          isBattleCardSelected ? null : +selectedPillId,
          onAccountQuotaClicked,
          fetchMoreGroupMeasures,
          refetchTerritoryGroupMeasuresTotals,
          setSelectedCell,
          isColumnHighlightingEnabled,
          setBulkUpsertQuotaGridFields,
          isQuotaGridBulkUpsertEnabled
        )
      );
    }

    setGridData(measuresGridData);
  };

  const getSelectedBattleCardVariables = (startRow, endRow, isFetchingTotal = false) => {
    const battlecardId = +selectedBattleCardId;
    const quotaComponentId = selectedQuotaComponentId;
    const requestVariables = { sheetId, battlecardId, quotaComponentId };
    if (isFetchingTotal) {
      return requestVariables;
    } else {
      return { ...requestVariables, startRow, endRow };
    }
  };

  const getSelectedTerritoryPillVariables = (startRow, endRow, isFetchingTotal = false) => {
    const battlecardId = +selectedBattleCardId;
    const territoryGroupId = +selectedPillId;
    const quotaComponentId = selectedQuotaComponentId;
    const requestVariables = { sheetId, battlecardId, quotaComponentId, territoryGroupId };
    if (isFetchingTotal) {
      return requestVariables;
    } else {
      return { ...requestVariables, startRow, endRow };
    }
  };

  const getQueryVariables = (startRow, endRow, isFetchingTotal = false): GetTerritoryQuotaVariables => {
    let variables;

    if (
      isBattleCardSelected &&
      selectedBattleCardId &&
      (!selectedPillId || selectedPillId === 'battlecard') &&
      selectedQuotaComponentId
    ) {
      //Request when a battle card is selected
      variables = getSelectedBattleCardVariables(startRow, endRow, isFetchingTotal);
    } else if (selectedBattleCardId && selectedPillId && selectedQuotaComponentId) {
      //Request when a pill is selected
      variables = getSelectedTerritoryPillVariables(startRow, endRow, isFetchingTotal);
    }
    return variables;
  };

  const handleOnGridReady = (gridEvent) => {
    setGridApi(gridEvent.api);
    const dataSource = {
      getRows: async (params) => {
        if (params.request.endRow === blockSize && params.api.getRenderedNodes().length === 1) {
          params.success({ rowData: gridData, rowCount: territoryMeasures?.totalTerritoriesCount });
          setRefreshGrid(false);
          setIsLoading(false);
        } else if (!territoryGroupMeasuresLoading) {
          const startRow = params?.request?.startRow + 1;
          const endRow = params?.request?.endRow;
          const variables = getQueryVariables(startRow, endRow);
          const fetchMore = await fetchMoreGroupMeasures({
            variables
          });
          const moreRows = buildMeasuresGridData(
            fetchMore?.data?.getTerritoryQuota,
            selectedBattleCardId,
            selectedQuotaComponentId,
            true
          );
          params.success({
            rowData: moreRows,
            rowCount: fetchMore?.data?.getTerritoryQuota?.totalTerritoriesCount
          });
          setIsLoading(false);
          setRefreshGrid(false);
        }
      }
    };

    gridEvent.api.setServerSideDatasource(dataSource);
    setErrorItems([]);
  };

  const onCellValueChanged = useCallback(
    async (event) => {
      const gridApi = event.api;
      const rowNode = event.node;
      const newValue = event.newValue;
      const ruleId = +event.node.data.ruleId;
      const variables = {
        ...territoryVariables,
        filter: {
          ruleId
        }
      };
      const columnKey = event.colDef.headerName;
      const currentNodeValue = event.data[columnKey]?.value;
      if (newValue && !!seasonalityFieldId && columnKey === GridHeaders.SEASONALITY) {
        const input = getSeasonalityVariables(event);
        if (isQuotaGridBulkUpsertEnabled) {
          const key = `${ruleId}_${columnKey}`;
          if (+newValue === +currentNodeValue) {
            return;
          }
          // Updates the bulk upsert input for a quota grid field.
          // If the fieldKey already exists in the previous state, it updates the fieldValue with the new value.
          // Otherwise, it adds a new entry for the fieldKey using the provided bulkUpsertInput.
          // Returns true after successfully updating the state.
          setBulkUpsertQuotaGridFields((prev) => ({
            ...prev,
            [key]: key in prev ? { ...prev[key], fieldValue: newValue } : input[0]
          }));
          return;
        }

        const result = await upsertFieldValues({
          variables: {
            input
          }
        });

        if (result?.data) {
          gridApi.flashCells({
            rowNodes: [rowNode],
            columns: [GridFields.SEASONALITY]
          });
          await refetchRowData(variables, gridApi, fetchMoreGroupMeasures, refetchTerritoryGroupMeasuresTotals);
        }
      }
    },
    [seasonalityFieldId]
  );

  const shouldShowGridLoading = isLoading || lookupsLoading;

  const handleLookupRowUpserted = () => {
    refetchLookups();
    setColumnDefs(null);
  };

  //TODO TQP-10451 add empty quota grid place holder https://varicent.atlassian.net/browse/TQP-10451

  return (
    <div className={b('gridContainer')} ref={containerRef} data-testid="quota-grid">
      <CanUser
        perform={UserAction.BATTLE_CARD_CANVAS_VIEW}
        yes={
          (isLoading || (selectedBattleCardId && gridData)) && (
            <AdvancedGrid
              gridOptions={getGridOptions()}
              columnDefs={columnDefs}
              data={JSON.stringify(gridData)}
              getRowNodeId={(data) => `${data.ruleId}`}
              gridWidth={containerRef?.current?.offsetWidth}
              gridHeight={containerRef?.current?.offsetHeight}
              showGridLoading={shouldShowGridLoading}
              suppressRowTransform
              data-testid="ag-quota-grid"
            />
          )
        }
        no={
          (isLoading || gridData) && (
            <AdvancedGrid
              gridOptions={getGridOptions()}
              columnDefs={columnDefs}
              data={JSON.stringify(gridData)}
              getRowNodeId={(data) => `${data.ruleId}`}
              gridWidth={containerRef?.current?.offsetWidth}
              gridHeight={containerRef?.current?.offsetHeight}
              showGridLoading={shouldShowGridLoading}
              suppressRowTransform
              data-testid="ag-quota-grid"
            />
          )
        }
      />
      <Dialog
        title={formatMessage('SEASONALITY_SCHEDULES')}
        isOpen={isLookupPreviewOpen}
        confirmButtonText={formatMessage('CLOSE')}
        showCancel={false}
        onSubmit={() => setIsLookupPreviewOpen(false)}
        className={b('seasonalityScheduleDialog')}
      >
        <span className={b('lookupPreviewContents')}>
          <LookupTableDetail showHeader={false} onRowUpserted={handleLookupRowUpserted} />
        </span>
      </Dialog>
    </div>
  );
};

export default QuotaGrid;
