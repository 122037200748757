import { Dispatch, SetStateAction } from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { ApolloQueryResult } from '@apollo/client';

import DropdownMenuCellEditor from 'app/components/AdvancedGrid/CellEditors/DropdownMenuCellEditor/DropdownMenuCellEditor';
import AllocatedCellRenderer from 'app/components/AdvancedGrid/CellRenderers/AllocatedCellRenderer/AllocatedCellRenderer';
import CurrencyCellRenderer from 'app/components/AdvancedGrid/CellRenderers/CurrencyCellRenderer/CurrencyCellRenderer';
import EditableTextCellRenderer from 'app/components/AdvancedGrid/CellRenderers/EditableTextCellRenderer/EditableTextCellRenderer';
import FooterCellRenderer from 'app/components/AdvancedGrid/CellRenderers/FooterCellRenderer/FooterCellRenderer';
import LookupInfoHeaderRenderer from 'app/components/AdvancedGrid/CellRenderers/LookupInfoHeaderRenderer/LookupInfoHeaderRenderer';
import MeasuresHeaderCellRenderer from 'app/components/AdvancedGrid/CellRenderers/MeasuresHeaderCellRenderer/MeasuresHeaderCellRenderer';
import NotApplicableCellRenderer from 'app/components/AdvancedGrid/CellRenderers/NotApplicableCellRenderer/NotApplicableCellRenderer';
import PercentCellRenderer from 'app/components/AdvancedGrid/CellRenderers/PercentCellRenderer/PercentCellRenderer';
import QuotaAdjustmentCellRenderer from 'app/components/AdvancedGrid/CellRenderers/QuotaAdjustmentCellRenderer/QuotaAdjustmentCellRenderer';
import TerritoryGroupRenderer from 'app/components/AdvancedGrid/CellRenderers/TerritoryGroupRenderer/TerritoryGroupRenderer';
import {
  getCellStyles,
  getDisabledInputs,
  getMeasureType,
  getSeasonalityCellRendererSelector,
  getSeasonalityLookUpsItems,
  getValueForSeasonality,
  setCellValueForSeasonality,
  shouldColumnBeExpandable,
  unitFormatter,
  getPeriodicityType,
  getPeriodicColumns,
  findParentMeasureAndPeriodicityMeasureHeader
} from 'app/components/AdvancedGrid/GridHelpers/QuotaGrid/QuotaGridHelper';
import {
  editMeasuresGridData,
  refetchRowData
} from 'app/components/AdvancedGrid/GridHelpers/QuotaGrid/utils/editMeasuresGridData';
import { editTerritoryQuotaComment } from 'app/components/AdvancedGrid/GridHelpers/QuotaGrid/utils/editTerritoryQuotaComment';

import {
  ALLOCATION_COLUMN_OFFSET,
  MEASURES_GRID_COLUMN_WIDTH,
  MEASURES_GRID_COLUMN_WIDTH_SMALL
} from 'app/global/variables';

import {
  GetTerritoryQuota,
  GetTerritoryQuotaTotalsVariables,
  GetTerritoryQuotaTotals,
  GetTerritoryQuota_getTerritoryQuota,
  TerritoryFieldValuesInput
} from 'app/graphql/generated/graphqlApolloTypes';

import {
  GridFields,
  GridHeaders,
  Lookup,
  MeasureFieldType,
  MeasureFormatType,
  PeriodicityType,
  QuotaGridColumnName,
  SelectedCell,
  SelectedQuotaDrillInTerritory,
  UserRoleType,
  HierarchySpec
} from 'app/models';
import { Column } from 'app/models/index';

import { formatMessage, formatNumber } from 'utils/messages/utils';

export enum BackgroundColours {
  GREEN = '#237804',
  DARK_BLUE = '#223273',
  BLUE = '#2b4ff4',
  PINK = '#eb2f96'
}

const buildMeasuresColumnDef = (
  territoryMeasuresData: GetTerritoryQuota_getTerritoryQuota,
  currency: string,
  isCurrencyConversionValid: boolean,
  callback: () => void,
  userRole: UserRoleType,
  totalsHeader: string,
  errorItems: Record<string, string[]>[],
  onPreviewDialogOpener: () => void,
  lookups: Lookup[],
  isBreakdownOfQuotaEnabled: boolean,
  quotaBreakdownHierarchies: HierarchySpec[],
  isSeasonalityEditable: boolean,
  selectedBattleCardId: number,
  selectedQuotaComponentId: number,
  selectedPillId?: number,
  onAccountQuotaClick?: (value: SelectedQuotaDrillInTerritory) => void,
  fetchMoreGroupMeasures?: ({ variables }) => Promise<{ data: GetTerritoryQuota }>,
  refetchTerritoryGroupMeasuresTotals?: (
    variables?: Partial<GetTerritoryQuotaTotalsVariables>
  ) => Promise<ApolloQueryResult<GetTerritoryQuotaTotals>>,
  setSelectedCell?: Dispatch<SetStateAction<SelectedCell>>,
  isColumnHighlightingEnabled?: boolean,
  setBulkUpsertQuotaGridFields?: Dispatch<SetStateAction<Record<string, TerritoryFieldValuesInput>>>,
  isQuotaGridBulkUpsertEnabled = false
): Array<Record<string, unknown>> => {
  const editMeasuresData = (currentNode, newValue, key) => {
    return editMeasuresGridData(
      currentNode,
      newValue,
      key,
      callback,
      setBulkUpsertQuotaGridFields,
      isQuotaGridBulkUpsertEnabled
    );
  };

  const updateQuota = (editedRowData, node, key) => {
    if (key === QuotaGridColumnName.QUOTA_ADJUSTMENT) {
      const allocatedQuota = parseFloat(editedRowData[QuotaGridColumnName.ALLOCATED_TD]?.value) || 0;
      const quotaAdjustment = parseFloat(editedRowData[QuotaGridColumnName.QUOTA_ADJUSTMENT]?.value) || 0;
      const updatedQuotaValue = allocatedQuota + quotaAdjustment;
      editedRowData[QuotaGridColumnName.UPDATED_QUOTA].value = updatedQuotaValue;
      node.setDataValue(QuotaGridColumnName.UPDATED_QUOTA, updatedQuotaValue);
    }
  };

  //TODO TQP-10452 verify if FE calculation for quota grid can be removed https://varicent.atlassian.net/browse/TQP-10453
  const onQuotaChange = async (newValue: number, currentNodeValue: number, params: ICellRendererParams) => {
    // avoiding unnecessary update if the value has not changed
    if (newValue === Number(currentNodeValue)) {
      return;
    }

    const key = params?.colDef?.field;

    handleDisableInput(params?.node, params?.api, key, true);

    const hasNodeUpdated = await prepareUpdate(params?.data, params?.node, params?.api, key, newValue);
    if (isQuotaGridBulkUpsertEnabled) {
      editMeasuresGridData(
        params?.node,
        newValue,
        key,
        callback,
        setBulkUpsertQuotaGridFields,
        isQuotaGridBulkUpsertEnabled
      );
      return;
    }

    if (params?.data[key]?.parentMeasure && hasNodeUpdated) {
      const parentKey = params?.data[key]?.parentMeasure;
      const currentParentValue = params?.api?.getValue(parentKey, params?.node);
      let newParentValue = 0;
      if (params?.data[key]?.periodicityType === PeriodicityType.YEARLY) {
        newParentValue = newValue;
      } else {
        newParentValue = newValue + currentParentValue - currentNodeValue;
      }
      if (
        params?.data[key]?.periodicityType === PeriodicityType.MONTHLY ||
        params?.data[key]?.periodicityType === PeriodicityType.QUARTERLY
      ) {
        const annualKey = `${parentKey}_${params?.data[key].year}`;
        const half = params.data[key]?.half;
        const halfKey = `${annualKey}_${half}`;
        const currentHalfValue = params.api.getValue(halfKey, params.node);
        const newHalfValue = newValue + currentHalfValue - currentNodeValue;
        // updating the half value
        preparePeriodicityUpdate(params.data, params.node, params.api, halfKey, newHalfValue);

        if (params.data[key].periodicityType === PeriodicityType.MONTHLY) {
          const quarter = params.data[key]?.quarter;
          const quarterKey = `${annualKey}_${quarter}`;

          const currentQuarterValue = params.api.getValue(quarterKey, params.node);
          const newQuarterValue = newValue + currentQuarterValue - currentNodeValue;

          // updating the quarter value
          preparePeriodicityUpdate(params.data, params.node, params.api, quarterKey, newQuarterValue);
        }

        const currentAnnualValue = params.api.getValue(annualKey, params?.node);
        const newAnnualValue = newValue + currentAnnualValue - currentNodeValue;

        // updating annual value
        preparePeriodicityUpdate(params.data, params.node, params.api, annualKey, newAnnualValue);
      }

      // updating the main column value
      preparePeriodicityUpdate(params.data, params.node, params.api, parentKey, newParentValue);
    }
  };

  const onCommentChange = async (newComment: string, params: ICellRendererParams) => {
    if (!params) return;
    const currentComment = params.data.comment;
    // avoiding unnecessary update if the comment has not changed
    if (newComment === currentComment) return;

    handleDisableInput(params.node, params.api, params.colDef?.headerName, true);
    const key = GridFields.COMMENT;
    try {
      await editTerritoryQuotaComment(params.data, newComment);
      params.node?.setDataValue(key, newComment);
    } catch (_error) {
      handleError(params.node, params.api, key);
    } finally {
      handleDisableInput(params.node, params.api, params.colDef?.headerName, false);
    }
  };

  const currencyFormatter = (params) => {
    const key = params.colDef.field;
    if (params?.data && params?.data[key]) {
      return formatNumber(params.data[key].value, { style: 'currency', currency });
    }
    return null;
  };

  const percentFormatter = (params) => {
    const key = params.colDef.field;
    if (params?.data && params?.data[key]) {
      // Formula type measure value returned from BE has already been converted to percentage format
      const value =
        params.data[key].measureFieldType === MeasureFieldType.FORMULA
          ? params.data[key].value
          : params.data[key].value / 100;
      return formatNumber(value, { style: 'percent', maximumFractionDigits: 2 });
    }

    return null;
  };

  const numericFormatter = (params) => {
    const key = params.colDef.field;
    if (params?.data && params?.data[key]) {
      return formatNumber(params.data[key].value);
    }
    return null;
  };

  const cellValueFormatter = (cell) => {
    if (cell.measureName === QuotaGridColumnName.ALLOCATION) {
      return percentFormatter;
    } else if (cell.measureName === QuotaGridColumnName.UNITS_SOLD) {
      return unitFormatter;
    } else if (cell.type === MeasureFormatType.NUMERIC) {
      return numericFormatter;
    } else if (cell.type === MeasureFormatType.PERCENTAGE) {
      return percentFormatter;
    } else {
      return currencyFormatter;
    }
  };

  const isAllocatedCellRenderer = (column: Column): boolean => {
    return (
      column?.measureName?.includes(QuotaGridColumnName.ALLOCATED_TD) &&
      !column?.measureName?.includes(QuotaGridColumnName.ALLOCATION) &&
      //eslint-disable-next-line no-restricted-syntax
      userRole !== UserRoleType.CONTRIBUTOR
    );
  };

  const isBreakdownOfQuotaColumn = (column: Column) => {
    return (
      column?.measureName === QuotaGridColumnName.UPDATED_QUOTA &&
      isBreakdownOfQuotaEnabled &&
      quotaBreakdownHierarchies.length > 0
    );
  };

  const getCellRenderer = (column?: Column, requiresValidCurrencyConversion = false) => {
    if (requiresValidCurrencyConversion && !isCurrencyConversionValid) {
      return NotApplicableCellRenderer;
    } else if (
      column?.measureName?.includes(QuotaGridColumnName.QUOTA_ADJUSTMENT) ||
      (column?.editable && !column?.measureName?.includes(QuotaGridColumnName.ALLOCATION))
    ) {
      return QuotaAdjustmentCellRenderer;
    } else if (isAllocatedCellRenderer(column)) {
      // TODO remove AllocatedCellRenderer TQP-10448 https://varicent.atlassian.net/browse/TQP-10448
      return AllocatedCellRenderer;
    } else if (column?.measureName?.includes(QuotaGridColumnName.ALLOCATION)) {
      return PercentCellRenderer;
    } else if (column?.measureName?.includes(QuotaGridColumnName.ACCOUNT_QUOTA) || isBreakdownOfQuotaColumn(column)) {
      return CurrencyCellRenderer;
    } else {
      return null;
    }
  };

  const quotaAdjustmentCellRendererErrorMessage = formatMessage('UPDATE_MEASURE_VALUE_ERROR');

  const getCellRendererParams = (column: Column, params: ICellRendererParams) => {
    if (column?.measureName?.includes(QuotaGridColumnName.ALLOCATION)) {
      return {
        highColour: BackgroundColours.GREEN,
        medHighColour: BackgroundColours.DARK_BLUE,
        medColour: BackgroundColours.BLUE,
        lowColour: BackgroundColours.PINK,
        columnName: column.measureName,
        columnWidth:
          params?.column?.getActualWidth() > 0
            ? params.column.getActualWidth() - ALLOCATION_COLUMN_OFFSET
            : MEASURES_GRID_COLUMN_WIDTH - ALLOCATION_COLUMN_OFFSET
      };
    } else if (column.measureName.includes(QuotaGridColumnName.ACCOUNT_QUOTA) || isBreakdownOfQuotaColumn(column)) {
      return {
        formatNumber,
        currency,
        onCellClick: ({ ruleId, territoryName, territoryId, territoryGroupId }) => {
          // once we remove the BREAKDOWN_OF_QUOTA_BY_HIERARCHIES FF, account quota drill in will be entirely replaced by hierarchy quota drill in
          // we should change this function name accordingly
          onAccountQuotaClick({ ruleId, territoryName, territoryId, territoryGroupId });
        },
        isLink: true
      };
    }

    if (column.editable) {
      //add special check for Quota adjustment to support drill down view for quota breakdown
      //User should only be able to edit in quota adjustment if there is no drill down view
      //Otherwise, if drill down view exists, quota adjustment will be a rollup of the values inside the view
      //and user will have to edit quota adjustment within the drill down view instead
      //Only show drill down view when quota is breaked down by hierarchies
      const shouldColumnBeEditable =
        column.measureName.includes(QuotaGridColumnName.QUOTA_ADJUSTMENT) && isBreakdownOfQuotaEnabled
          ? quotaBreakdownHierarchies.length === 0
          : true;

      const currentNodeValue = params.data[params?.colDef?.headerName]?.value;
      return {
        value: currentNodeValue,
        isEditable: shouldColumnBeEditable,
        currency: column?.type === MeasureFormatType.CURRENCY ? currency : null,
        type: column?.type,
        errorItems,
        header: params?.colDef?.headerName,
        errorMessage: quotaAdjustmentCellRendererErrorMessage,
        disabledCellInputs,
        onBlur: async (newValue) => {
          onQuotaChange(+newValue, +currentNodeValue, params);
          clearError(params?.node, params?.api);
        }
      };
    }

    return { currency };
  };

  const setValues = (newValue, editedRowData, node, key) => {
    if (editedRowData[key]) {
      editedRowData[key].value = isNaN(parseFloat(newValue)) ? '' : parseFloat(newValue);

      updateQuota(editedRowData, node, key);
    }
  };

  let disabledCellInputs: Record<string, string[]>[] = [];

  const clearError = (node, api) => {
    const errorItem = errorItems?.find((item) => item[node.id]);
    if (errorItem) {
      errorItems.splice(errorItems.indexOf(errorItem));
    }

    api?.refreshCells({ force: true, suppressFlash: true });
  };

  const handleError = (node, api, key) => {
    const errorItem = errorItems?.find((item) => item[node.id]);

    // to determine which columns at what row node have errors, one row node could have errors for allocated adjustment, quota adjustment, and comments which in this case the errorItems would be: [rowNodeId]: [allocated td, quota adjustment, comments]
    if (errorItem) {
      errorItem[node.id].push(key);
    } else {
      errorItems.push({ [node.id]: [key] });
    }
    api?.refreshCells({ force: true, suppressFlash: true });
  };

  const prepareUpdate = async (data, currentNode, api, key, newValue) => {
    if (data[key]?.periodicityType || data[key]?.periodicityType === PeriodicityType.NONE) {
      const result = await editMeasuresData(currentNode, newValue, key);
      handleDisableInput(currentNode, api, key, false);
      if (result) {
        handleSuccessfulUpdate(currentNode, key, data, newValue);
      } else {
        handleError(currentNode, api, key);
        return false;
      }
    }

    return true;
  };

  const handleSuccessfulUpdate = (currentNode, key, data, newValue) => {
    currentNode.setDataValue(key, newValue);
    setValues(newValue, data, currentNode, key);
  };

  const preparePeriodicityUpdate = async (data, currentNode, api, key, newValue) => {
    handleSuccessfulUpdate(currentNode, key, data, newValue);
    const { ruleId } = currentNode.data;
    const variables = {
      battlecardId: selectedBattleCardId,
      isTQM: false,
      quotaComponentId: selectedQuotaComponentId,
      territoryGroupId: selectedPillId,
      filter: {
        ruleId
      }
    };
    const result = await refetchRowData(variables, api, fetchMoreGroupMeasures, refetchTerritoryGroupMeasuresTotals);

    if (result) {
      setSelectedCell({
        rowIndex: currentNode.rowIndex,
        column: key,
        value: currentNode.data[key].value
      });
    }
  };

  const handleDisableInput = (node, api, key, shouldDisable) => {
    disabledCellInputs = getDisabledInputs(disabledCellInputs, node, key, shouldDisable);

    api.refreshCells({ rowNodes: [node], force: true, suppressFlash: true });
  };

  const columnDefs = [];

  if (territoryMeasuresData) {
    const columns: Column[] = [];
    const columnsWithTotals = [];
    let periodicityType = PeriodicityType.NONE;
    const { periodicTerritories: territories } = territoryMeasuresData;

    territories[0]?.measures?.forEach((measure) => {
      const measureName = measure.measureName;
      const hasMultipleMeasureValue = measure?.measureValue?.years?.length > 1;
      periodicityType = getPeriodicityType(measure);
      const measureFieldType = measure.measureValue.measureFieldType;
      columns.push({
        measureName,
        editable: measure.editable,
        expandable: shouldColumnBeExpandable(
          measureName,
          measure.editable,
          periodicityType,
          hasMultipleMeasureValue,
          measureFieldType
        ),
        type: measure.measureValue.type,
        measureFieldType: measure.measureValue.measureFieldType
      });
      columnsWithTotals.push(measureName);
      if (measure?.measureValue?.years) {
        const periodicColumns = getPeriodicColumns(measure, measureName);
        periodicityType = getPeriodicityType(measure);
        columns.push(...periodicColumns.columns);
        columnsWithTotals.push(...periodicColumns.columnsWithTotals);
      } else {
        columns.push({
          measureName,
          editable: measure.editable,
          expandable: shouldColumnBeExpandable(measureName, measure.editable, periodicityType, hasMultipleMeasureValue),
          type: measure.measureValue.type,
          measureFieldType: measure.measureValue.measureFieldType
        });
        if (measure.measureValue !== null) {
          columnsWithTotals.push(measure.measureName);
        }
      }
    });

    columnDefs.push({
      headerName: GridHeaders.TERRITORY_ID,
      field: GridFields.TERRITORY_ID,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
      width: MEASURES_GRID_COLUMN_WIDTH_SMALL,
      pinned: 'left',
      cellRendererFramework: getCellRenderer(),
      resizable: true
    });

    columnDefs.push({
      headerName: QuotaGridColumnName.TERRITORY_NAME,
      field: GridFields.TERRITORY_NAME,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
      width: MEASURES_GRID_COLUMN_WIDTH_SMALL,
      pinned: 'left',
      cellRendererFramework: getCellRenderer(),
      resizable: true
    });

    columnDefs.push({
      headerName: QuotaGridColumnName.TERRITORY_GROUP,
      field: GridFields.TERRITORY_GROUP,
      flex: 1,
      minWidth: MEASURES_GRID_COLUMN_WIDTH,
      cellRendererFramework: TerritoryGroupRenderer,
      cellRendererParams: { isQuotaGrid: true },
      resizable: true
    });

    columnDefs.push({
      headerName: QuotaGridColumnName.TERRITORY_GROUP_TYPE,
      field: GridFields.TERRITORY_GROUP_TYPE,
      flex: 1,
      minWidth: MEASURES_GRID_COLUMN_WIDTH,
      cellRendererSelector: (params: ICellRendererParams) => {
        if (params?.node?.rowPinned) {
          return {
            frameworkComponent: ''
          };
        } else {
          return {
            frameworkComponent: TerritoryGroupRenderer
          };
        }
      },
      resizable: true
    });

    // render the grid's "measures columns"d
    columns.forEach((column: Column) => {
      if (column.measureName === QuotaGridColumnName.SEASONALITY) {
        columnDefs.push({
          headerName: GridHeaders.SEASONALITY,
          field: GridFields.SEASONALITY,
          editable: isSeasonalityEditable,
          flex: 1,
          minWidth: 250,
          resizable: true,
          headerComponentFramework: LookupInfoHeaderRenderer,
          valueGetter: (params) => getValueForSeasonality(params),
          cellRendererSelector: (params: ICellRendererParams) => getSeasonalityCellRendererSelector(params),
          cellEditorFramework: DropdownMenuCellEditor,
          valueSetter: (params) => setCellValueForSeasonality(params),
          cellEditorParams: () => getSeasonalityLookUpsItems(lookups),
          headerComponentParams: {
            displayName: QuotaGridColumnName.SEASONALITY,
            hover: formatMessage('VIEW_SEASONALITY_DATA'),
            tooltipMessage: formatMessage('SEASONALITY_TOOLTIP_MESSAGE'),
            onButtonClicked: onPreviewDialogOpener
          }
        });
      } else {
        const shouldHideAccountQuotaColumn =
          column.measureName.includes(QuotaGridColumnName.ACCOUNT_QUOTA) && isBreakdownOfQuotaEnabled;
        const { parentMeasure, periodicityMeasure } = findParentMeasureAndPeriodicityMeasureHeader(columns, column);
        columnDefs.push({
          headerName: column.measureName,
          hide:
            column.expandable === undefined ||
            [QuotaGridColumnName.PRIOR_YEAR_TARGET, QuotaGridColumnName.SALES, QuotaGridColumnName.UNITS_SOLD].includes(
              column.measureName as QuotaGridColumnName
            ) ||
            shouldHideAccountQuotaColumn,
          field: column.measureName,
          type: column.type,
          parentMeasure,
          periodicityMeasure,
          measureFieldType: column.measureFieldType,
          headerClass: totalsHeader,
          flex: column.measureName === QuotaGridColumnName.ALLOCATION ? null : 1,
          minWidth: MEASURES_GRID_COLUMN_WIDTH,
          cellStyle: (params) => getCellStyles(params, column, isColumnHighlightingEnabled),
          valueGetter: (params) => {
            const key = params?.colDef?.field;
            if (params?.data && params?.data[key]) {
              return params.data[key].value;
            }
            return null;
          },
          valueFormatter: cellValueFormatter(column),
          cellRendererFramework: getCellRenderer(column, true),
          headerComponentParams: {
            headerClass: totalsHeader,
            measureName: column.measureName,
            shouldHeaderBeExpandable: column.expandable
          },
          headerComponentFramework: MeasuresHeaderCellRenderer,
          valueSetter: () => {
            return true;
          },
          cellRendererSelector: (params: ICellRendererParams) => {
            if (params?.node?.rowPinned) {
              return {
                frameworkComponent: FooterCellRenderer,
                params: {
                  formatNumber,
                  currency,
                  measureType: getMeasureType(params, columnsWithTotals, isCurrencyConversionValid),
                  measureFieldType: params.colDef['measureFieldType']
                }
              };
            } else {
              return {
                frameworkComponent: getCellRenderer(column, true),
                params: getCellRendererParams(column, params)
              };
            }
          },
          resizable: column.measureName !== QuotaGridColumnName.ALLOCATION
        });
      }
    });

    columnDefs.push({
      headerName: QuotaGridColumnName.COMMENTS,
      field: GridFields.COMMENT,
      flex: 1,
      minWidth: 500,
      cellStyle: () => {
        return {
          border: 'none'
        };
      },
      cellRendererSelector: (params: ICellRendererParams) => {
        if (params?.node?.rowPinned) {
          return '';
        } else {
          return {
            frameworkComponent: EditableTextCellRenderer,
            params: {
              onBlur: async (newComment) => {
                await onCommentChange(newComment, params);
              },
              value: params?.value,
              errorItems,
              errorMessage: formatMessage('COMMENT_ERROR'),
              disabledCellInputs
            }
          };
        }
      },
      resizable: true
    });
  }

  return columnDefs;
};

export default buildMeasuresColumnDef;
