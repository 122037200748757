import { Dispatch, SetStateAction } from 'react';

import { GridApi, RowNode } from '@ag-grid-community/core';
import { ApolloQueryResult } from '@apollo/client';

import { formatTotalsFooter } from 'app/components/AdvancedGrid/GridHelpers/QuotaGrid/TotalsFooter/TotalsFooterHelper';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import {
  GetTerritoryQuota,
  GetTerritoryQuotaVariables,
  GetTerritoryQuotaTotals,
  GetTerritoryQuotaTotalsVariables,
  GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals,
  TerritoryFieldValuesInput
} from 'app/graphql/generated/graphqlApolloTypes';
import { UPSERT_MEASURE_VALUE } from 'app/graphql/mutations/upsertMeasureValue';

export const editMeasuresGridData = async (
  currentNode: RowNode,
  newValue: number,
  key: string,
  onUpdateMeasure: () => void,
  setBulkUpsertQuotaGridFields: Dispatch<SetStateAction<Record<string, TerritoryFieldValuesInput>>>,
  isQuotaGridBulkUpsertEnabled = false
): Promise<boolean> => {
  const rowData = currentNode.data;
  const { battlecardId, territoryGroupId, territoryId, ruleId, quotaComponentId } = rowData;
  const measureId = rowData[key]?.id;
  const adate = rowData[key]?.date;
  const currentNodeValue = rowData[key]?.value;
  const commonInput = {
    territoryId,
    territoryGroupId,
    battlecardId: +battlecardId,
    quotaComponentId,
    adate,
    ruleId
  };

  const bulkUpsertInput = {
    fieldId: +measureId,
    fieldValue: newValue.toString(),
    ...commonInput
  };

  if (isQuotaGridBulkUpsertEnabled) {
    const fieldKey = `${ruleId}_${key}`;
    if (+newValue === +currentNodeValue) {
      return null;
    }
    // Updates the bulk upsert input for a quota grid field.
    // If the fieldKey already exists in the previous state, it updates the fieldValue with the new value.
    // Otherwise, it adds a new entry for the fieldKey using the provided bulkUpsertInput.
    // Returns true after successfully updating the state.
    setBulkUpsertQuotaGridFields((prev) => ({
      ...prev,
      [fieldKey]: fieldKey in prev ? { ...prev[fieldKey], fieldValue: newValue.toString() } : bulkUpsertInput
    }));
    return true;
  }

  try {
    // eslint-disable-next-line no-restricted-syntax
    await apolloClient.mutate({
      mutation: UPSERT_MEASURE_VALUE,
      variables: {
        input: {
          measureId,
          measureValue: Number(newValue),
          ...commonInput
        }
      }
    });
    onUpdateMeasure();
  } catch (error) {
    console.log(error);
    return false;
  }

  return true;
};

const getGenericPeriodicTotals = (input: {
  data: GetTerritoryQuotaTotals;
}): GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals[] | null => {
  if (!input?.data) return null;
  if ('getTerritoryQuota' in input.data) return input.data.getTerritoryQuota?.periodicTotals;
  return null;
};

export const refetchRowData = async (
  variables: GetTerritoryQuotaVariables,
  gridApi: GridApi,
  fetchMoreGroupMeasures: ({ variables }) => Promise<{
    data: GetTerritoryQuota;
  }>,
  refetchTerritoryGroupMeasuresTotals: (
    variables?: Partial<GetTerritoryQuotaTotalsVariables>
  ) => Promise<ApolloQueryResult<GetTerritoryQuotaTotals>>
): Promise<boolean> => {
  const [refetchTerritoryMeasures, refetchTerritoryMeasuresTotals] = await Promise.all([
    fetchMoreGroupMeasures({ variables }),
    refetchTerritoryGroupMeasuresTotals()
  ]);
  const hasData = refetchTerritoryMeasures?.data && refetchTerritoryMeasures.data['getTerritoryQuota'];
  const newTotal = getGenericPeriodicTotals(refetchTerritoryMeasuresTotals);
  if (hasData && newTotal) {
    gridApi.refreshServerSideStore({ purge: false });

    const totals = formatTotalsFooter(newTotal);
    gridApi.setPinnedBottomRowData(totals);

    return true;
  }
  return false;
};
