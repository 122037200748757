import { Dispatch, SetStateAction } from 'react';

import { ColDef, ICellRendererParams } from '@ag-grid-community/core';

import MeasuresHeaderCellRenderer from 'app/components/AdvancedGrid/CellRenderers/MeasuresHeaderCellRenderer/MeasuresHeaderCellRenderer';
import QuotaAdjustmentCellRenderer from 'app/components/AdvancedGrid/CellRenderers/QuotaAdjustmentCellRenderer/QuotaAdjustmentCellRenderer';
import {
  shouldColumnBeExpandable,
  getCellStyles,
  getPeriodicityType,
  getPeriodicColumns,
  findParentMeasureAndPeriodicityMeasureHeader
} from 'app/components/AdvancedGrid/GridHelpers/QuotaGrid/QuotaGridHelper';

import { MEASURES_GRID_COLUMN_WIDTH, MEASURES_GRID_COLUMN_WIDTH_SMALL } from 'app/global/variables';

import {
  GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution,
  TerritoryFieldValuesInput,
  TerritoryMeasureValueInput
} from 'app/graphql/generated/graphqlApolloTypes';

import { PeriodicityType, QuotaGridColumnName, MeasureFormatType } from 'app/models';
import { Column } from 'app/models/index';

import { formatNumber } from 'utils/messages/utils';

const buildHierarchyQuotaDistributionColumnDefs = (
  data: GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution[],
  currency: string,
  headerClass: string,
  upsertQuotaAdjustment,
  territoryId: string,
  territoryGroupId: number,
  ruleId: number,
  battlecardId: number,
  setBulkUpsertQuotaGridFields: Dispatch<SetStateAction<Record<string, TerritoryFieldValuesInput>>>,
  isQuotaGridBulkUpsertEnabled = false
): ColDef[] => {
  const columnDefs = [];

  const currencyFormatter = (params) => {
    const key = params.colDef.field;
    if (params.data?.[key]) {
      return formatNumber(params.data[key].value, { style: 'currency', currency });
    }
    return null;
  };

  const getCellRenderer = (column: Column) => {
    if (column.measureName?.includes(QuotaGridColumnName.QUOTA_ADJUSTMENT)) {
      return QuotaAdjustmentCellRenderer;
    } else {
      return null;
    }
  };

  const handleSingleUpsertFieldValue = (columnKey, rowData, newValue, currentNodeValue) => {
    if (+newValue === +currentNodeValue) {
      return;
    }
    const upsertInput: TerritoryMeasureValueInput = {
      territoryId,
      territoryGroupId,
      battlecardId,
      measureId: rowData[columnKey].id,
      quotaComponentId: rowData[columnKey].quotaComponentId,
      measureValue: +newValue,
      adate: rowData[columnKey].date,
      ruleId
    };
    if (rowData.customerAccountHierarchyId) {
      upsertInput.customerAccountHierarchyId = rowData.customerAccountHierarchyId;
    }
    if (rowData.customHierarchyId_1) {
      upsertInput.customHierarchyId_1 = rowData.customHierarchyId_1;
    }
    if (rowData.customHierarchyId_2) {
      upsertInput.customHierarchyId_2 = rowData.customHierarchyId_2;
    }

    upsertQuotaAdjustment({ variables: { input: upsertInput } });
  };

  const handleBulkUpsertQuotaGridFields = (bulkUpsertFieldInput, setBulkUpsertQuotaGridFields) => {
    const { ruleId, territoryGroupId, battlecardId, territoryId, rowData, currentNodeValue, columnKey, newValue } =
      bulkUpsertFieldInput;
    if (+newValue === +currentNodeValue) {
      return;
    }

    const upsertFieldValuesInput = {
      territoryId,
      territoryGroupId,
      battlecardId,
      fieldId: rowData[columnKey].id,
      quotaComponentId: rowData[columnKey].quotaComponentId,
      fieldValue: newValue,
      adate: rowData[columnKey].date,
      ruleId
    };
    let key = `${ruleId}_${columnKey}`;

    if (rowData.customerAccountHierarchyId) {
      upsertFieldValuesInput['customerAccountHierarchyId'] = rowData.customerAccountHierarchyId;
      key += `_${rowData.customerAccountHierarchyId}`;
    }
    if (rowData.customHierarchyId_1) {
      upsertFieldValuesInput['customHierarchyId_1'] = rowData.customHierarchyId_1;
      key += `_${rowData.customHierarchyId_1}`;
    }
    if (rowData.customHierarchyId_2) {
      upsertFieldValuesInput['customHierarchyId_2'] = rowData.customHierarchyId_2;
      key += `_${rowData.customHierarchyId_1}`;
    }

    // Updates the bulk upsert input for a quota grid field.
    // If the fieldKey already exists in the previous state, it updates the fieldValue with the new value.
    // Otherwise, it adds a new entry for the fieldKey using the provided bulkUpsertInput.
    // Returns true after successfully updating the state.
    setBulkUpsertQuotaGridFields((prev) => ({
      ...prev,
      [key]: key in prev ? { ...prev[key], fieldValue: newValue } : upsertFieldValuesInput
    }));
  };

  const getCellRendererParams = (column: Column, currency: string, params: ICellRendererParams) => {
    if (column.editable) {
      const currentNodeValue = params.data[params?.colDef?.headerName]?.value;
      return {
        value: currentNodeValue,
        isEditable: true,
        currency: column.type === MeasureFormatType.CURRENCY ? currency : null,
        type: column.type,
        header: params?.colDef?.headerName,
        onBlur: async (newValue) => {
          const key = column.measureName;
          const rowData = params.data;
          if (isQuotaGridBulkUpsertEnabled) {
            const bulkUpsertFieldInput = {
              ruleId,
              territoryGroupId,
              battlecardId,
              territoryId,
              rowData,
              currentNodeValue,
              columnKey: key,
              newValue
            };
            handleBulkUpsertQuotaGridFields(bulkUpsertFieldInput, setBulkUpsertQuotaGridFields);
          } else {
            handleSingleUpsertFieldValue(key, rowData, newValue, currentNodeValue);
          }
        }
      };
    }

    return {};
  };

  // quota breakdown by hierarchy columns are controlled by the GetTerritoryQuotaDistribution response
  // it returns a list of hierarchies which were used for the most recent TDA run when quota breakdown by hierarchy was enabled
  const quotaBreakdownHierarchies = data[0]?.hierarchies || [];
  quotaBreakdownHierarchies.forEach((hierarchy) => {
    // hierarchy key column
    columnDefs.push({
      headerName: hierarchy.rootKey,
      field: `${hierarchy.rootKey}_key`,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
      pinned: 'left',
      flex: 1,
      resizable: true
    });
    // hierarchy name column
    columnDefs.push({
      headerName: hierarchy.rootName,
      field: `${hierarchy.rootName}_name`,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
      pinned: 'left',
      flex: 1,
      resizable: true
    });
  });

  // measure columns are controlled by the quota distribution sheet
  let periodicityType = PeriodicityType.NONE;
  const measureColumns: Column[] = [];

  const allMeasures = data[0]?.measures || [];
  allMeasures.forEach((measure) => {
    const measureName = measure.measureName;
    const hasMultipleMeasureValue = measure.measureValue.years?.length > 1;
    periodicityType = getPeriodicityType(measure);
    const measureFieldType = measure.measureValue.measureFieldType;
    measureColumns.push({
      measureName,
      editable: measure.editable,
      expandable: shouldColumnBeExpandable(
        measureName,
        measure.editable,
        periodicityType,
        hasMultipleMeasureValue,
        measureFieldType
      ),
      type: measure.measureValue.type,
      measureFieldType: measure.measureValue.measureFieldType
    });
    if (measure.measureValue.years) {
      const periodicColumns = getPeriodicColumns(measure, measureName);
      measureColumns.push(...periodicColumns.columns);
    }
  });

  measureColumns.forEach((column) => {
    const { parentMeasure, periodicityMeasure } = findParentMeasureAndPeriodicityMeasureHeader(measureColumns, column);
    columnDefs.push({
      headerName: column.measureName,
      hide: column.measureName.includes('_'),
      field: column.measureName,
      type: column.type,
      parentMeasure,
      periodicityMeasure,
      measureFieldType: column.measureFieldType,
      headerClass,
      flex: 1,
      minWidth: MEASURES_GRID_COLUMN_WIDTH,
      resizable: true,
      cellStyle: (params) => getCellStyles(params, column),
      valueGetter: (params) => {
        const key = params?.colDef?.field;
        if (params?.data && params?.data[key]) {
          return params.data[key].value;
        } else {
          return null;
        }
      },
      valueFormatter: currencyFormatter,
      headerComponentParams: {
        headerClass,
        measureName: column.measureName,
        shouldHeaderBeExpandable: column.expandable
      },
      headerComponentFramework: MeasuresHeaderCellRenderer,
      cellRendererSelector: (params: ICellRendererParams) => {
        if (params?.node?.rowPinned) {
          return null;
        } else {
          return {
            frameworkComponent: getCellRenderer(column),
            params: getCellRendererParams(column, currency, params)
          };
        }
      }
    });
  });

  return columnDefs;
};

export default buildHierarchyQuotaDistributionColumnDefs;
